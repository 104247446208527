export function generateMeta(data) {
  let title, logo, description, thumbnail

  if (data && data.hub && data.hub.name) {
    // Hub or header

    // Extract logo, title, illustration and description of the first story available
    const hubContent = data.hub
    logo = hubContent.logo || '/favicon.ico'
    title = hubContent.name
    description = hubContent.description || ''
    thumbnail = hubContent.background_img
  } else if (data) {
    // Individual clip

    const storiesData = data.stories
    if (storiesData && storiesData.length > 0) {
      // Extract logo, title, illustration and description of the first story available
      const story = storiesData[0]
      const storyContent = story.content

      logo = storyContent.logo_top_left_img || '/favicon.ico'
      title =
        (storyContent.button_above_title &&
        storyContent.button_above_title.length > 0
          ? storyContent.button_above_title
          : storyContent.logo_top_left_title) || ''
      description = story.description.content || ''
      thumbnail = story.header_thumbnail_big || logo
    }
  }
  // Create all meta tags from them
  return {
    title,
    meta: [
      /* Meta title */
      { hid: 'og:title', property: 'og:title', content: title },
      { name: 'twitter:title', content: title },
      /* Meta preview */
      { hid: 'og:image', property: 'og:image', content: thumbnail },
      { name: 'twitter:image', content: thumbnail },
      /* Meta description */
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      { name: 'twitter:description', content: description },
      { hid: 'description', name: 'description', content: description },
      /* Additional meta */
      { name: 'twitter:card', content: 'summary' },
      { hid: 'og:type', property: 'og:type', content: 'website' },
    ],
    link: [
      { rel: 'apple-touch-icon', sizes: '57x57', href: logo },
      { rel: 'apple-touch-icon', sizes: '60x60', href: logo },
      { rel: 'apple-touch-icon', sizes: '72x72', href: logo },
      { rel: 'apple-touch-icon', sizes: '76x76', href: logo },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: logo },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: logo },
    ],
  }
}
