//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import Hub from '../components/hub/Hub'
// eslint-disable-next-line camelcase
import api_client from '~/utilities/CliprNoCookieAPI'
import { getHubRequestPath } from '~/services/request'
import { generateMeta } from '~/services/meta'

export default {
  name: 'App',
  components: {
    Hub,
  },
  async asyncData({ req, params, $sentry }) {
    const cliprTestMode = false

    // Retrieve stories content
    const path = getHubRequestPath(req, params, cliprTestMode)
    const request = api_client()
    try {
      const response = await request.get(path)
      return {
        cliprTestMode,
        headData: generateMeta(response.data, true),
        initialData: response.data,
      }
    } catch (e) {
      const status = get(e, 'response.status', 0)
      if ($sentry && (status >= 500 || !e.response)) {
        $sentry.captureException(e)
      }
      return {}
    }
  },
  data() {
    return {
      cliprSimulator: false,
      headData: {
        title: '',
        meta: [],
        link: [],
      },
      initialData: null,
    }
  },
  head() {
    return {
      title: this.headData.title,
      link: this.headData.link.concat([
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Work+Sans:400,600,700',
        },
      ]),
      meta: this.headData.meta,
    }
  },
}
